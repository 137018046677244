require('@rails/ujs').start()
require('turbolinks').start()
require('jquery') // for cocoon
require('@nathanvda/cocoon') // for nested forms

import axios from 'axios'
window.axios = axios

import '@popperjs/core'
import { patchBootstrap } from './shared_helpers'
import { Modal } from 'bootstrap'
window.Modal = Modal


import '../stylesheets/application'

document.addEventListener('DOMContentLoaded', function (event) {
  patchBootstrap()
})
